<template>
  <div class="page-users-following">
    <following-table :items="USER_FOLLOWING"></following-table>
    
    <nothing-here-yet 
      v-if="!isLoading && (!USER_FOLLOWING || USER_FOLLOWING.length === 0)"
      class="nothing-here-spacing"
    ></nothing-here-yet>

    <pagination
        v-if="USER_FOLLOWING && USER_FOLLOWING.length && COUNT_USER_FOLLOWING > 30"
        :count="COUNT_USER_FOLLOWING"
        :currentPage="CURRENT_PAGE_USER_FOLLOWING"
        :commit="'SET_CURRENT_PAGE_USER_FOLLOWING'">
    </pagination>
  </div>
</template>

<style scoped>
.nothing-here-spacing {
  margin-top: 40px;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import followingTable from "@/components/follow/table/table";
import pagination from "@/components/pagination";
import nothingHereYet from "@/components/small/nothingHereYet";

export default {
  name: "FollowingBase",
  components: {
    followingTable,
    pagination,
    nothingHereYet
  },
  data() {
    return {
      type: undefined
    }
  },
  watch: {
    CURRENT_PAGE_USER_FOLLOWING() {
      this.getUserFollowing();
    }
  },
  computed: {
    ...mapGetters(['FOLLOWS_FILTER', 'USER_FOLLOWING', 'COUNT_USER_FOLLOWING', 'CURRENT_PAGE_USER_FOLLOWING', 'NEED_REFRESH_USER_FOLLOWING']),
  },
  mounted() {
    this.$store.commit('SET_CURRENT_PAGE_USER_FOLLOWING', 1);
  },
  methods: {
    ...mapActions(['GET_USER_FOLLOWING']),

    getUserFollowing() {
      if (this.type === undefined) return;

      this.$store.commit('SET_FOLLOW_LOADER', true);

      let params = {
        page: this.CURRENT_PAGE_USER_FOLLOWING,
        type: this.type
      };

      this.GET_USER_FOLLOWING(params)
          .catch(err => console.log(`GET_USER_FOLLOWING, ${err}`))
          .finally(() =>  this.$store.commit('SET_FOLLOW_LOADER', false));
    },

    scrollToTop() {
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.commit('SET_CURRENT_PAGE_USER_FOLLOWING', 1);
      vm.getUserFollowing();
      vm.scrollToTop();
    });
  }
}
</script>
