<template>
  <div class="item_row heading">
    <div class="ava">
      <div class="view_list"></div>
      <div class="view_map"></div>
    </div>
    <div class="name">
      <span>Name</span>
    </div>
    <div class="location">Location</div>
    <div class="followers">
      <span>Followers</span>
    </div>
    <div class="more"></div>
  </div>
</template>

<script>

export default {
    name: "TableHeader",
}
</script>
