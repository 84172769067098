import { render, staticRenderFns } from "./table.vue?vue&type=template&id=004fc2a7&scoped=true"
import script from "./table.vue?vue&type=script&lang=js"
export * from "./table.vue?vue&type=script&lang=js"
import style0 from "./table.vue?vue&type=style&index=0&id=004fc2a7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "004fc2a7",
  null
  
)

export default component.exports