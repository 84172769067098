<template>
  <FollowingBase></FollowingBase>
</template>

<script>
import FollowingBase from "@/pages/follows/following/FollowingBase";

export default {
  name: 'FollowingLabels',
  components: {FollowingBase},
  extends: FollowingBase,
  data() {
    return {
      type: 'record_labels'
    }
  }
}
</script>
