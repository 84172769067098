<template>
    <div class="grid_table audience">
        <table-header></table-header>

        <template v-if="FOLLOW_LOADER">
          <v-skeleton-loader
            type="image"
            class="table_item_row"
            v-for="index in 31"
            :key="index">
          </v-skeleton-loader>
        </template>

        <template v-if="items && items.length">
            <div class="item_row data" v-for="item of items" :key="'A' + item.id + dynamicKey" @click="handleRowClick(item)">
                <div class="ava">
                  <img v-if="item.photo && item.photo.thumbnail"
                       v-bind:srcset="`${item.photo.thumbnail['50x50']}?cache=${cacheKey} 1x, ${item.photo.thumbnail['50x50@2x']}?cache=${cacheKey} 2x`"
                       :src="`${item.photo.thumbnail['50x50']}?cache=${cacheKey}`" alt="photo">
                    <div v-if="!item.photo || !item.photo.thumbnail" class="default_ava"></div>
                </div>

                <div class="name">
                    <span class="user_name" @click="redirectToProfile(item)">
                      {{ item.name }}
                    </span>
                    <span class="user_type">{{ typeLabel(item) }}</span>
                </div>

                <div class="location">
                  <template v-if="item.location">{{ item.location }}</template>
                  <template v-else>Planet Earth</template>
                </div>

                <div class="followers">
                  {{ item.followers }}
                </div>

                <div class="more">
                    <v-menu
                            disabled
                            transition="slide-y-transition"
                            :close-on-content-click="false"
                            :nudge-width="120"
                            offset-y
                            nudge-left="60"
                            nudge-bottom="15"
                            origin="top center"
                            content-class="menu-pointer charcoal">
                        <template v-slot:activator="{ on, attrs }">
                            <button type="button" v-ripple class="more_menu" v-bind="attrs" v-on="on"></button>
                        </template>
                        <v-list>
                            <v-list-item v-ripple>
                                <button type="button" @click="unfollow(item)">Unfollow</button>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </div>
        </template>

        <template v-if="!FOLLOW_LOADER && !items">
            <nothingHereYet></nothingHereYet>
        </template>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import nothingHereYet from "@/components/small/nothingHereYet";
import tableHeader from "@/components/follow/table/header.vue";

const typeToRouteMap = {
  user: 'user-profile',
  artist: 'artist-profile',
  record_label: 'label-profile'
};

export default {
    name: "Table",
    props: ['items'],
    components: {
      tableHeader,
      nothingHereYet
    },
    data() {
        return {
            loader: false,
            dynamicKey: 0,
            cacheKey: new Date().getTime()
        }
    },
    computed: {
        ...mapGetters(['FOLLOW_LOADER']),
    },
    mounted() {
        this.loader = true;
    },
	methods: {
		...mapActions(['ARTIST_UNFOLLOW', 'UNFOLLOW']),

    redirectToProfile(item) {
      const routeName = typeToRouteMap[item.type];
      if (routeName) {
        this.$router.push({
          name: routeName,
          params: {
            id: item.id,
            name: window.toHumanFormat(item.name)
          }
        });
      }
		},

    unfollow(item) {
      const unfollowMethods = {
        artists: this.ARTIST_UNFOLLOW,
        users: this.UNFOLLOW
      };

      const unfollowMethod = unfollowMethods[item.type];

      if (unfollowMethod) {
        unfollowMethod.call(this, item.id)
            .then(result => {
              if (result === 'success') {
                const index = this.items.findIndex(el => el.id === item.id);
                if (index !== -1) {
                  this.items.splice(index, 1);
                }
              }
            })
            .catch(err => console.error(`${item.type} unfollow, ${err}`));
      }
    },

    typeLabel(item) {
      const typesMap = {
        user: 'User',
        artist: 'Artist',
        record_label: 'Record Label'
      };

      return typesMap[item.type] ?? ''
    },

    handleRowClick(item) {
        if (window.innerWidth <= 479) {
            this.redirectToProfile(item);
        }
    }
	}
}
</script>

<style scoped>
@media screen and (max-width: 479px) {
    .item_row.data {
        cursor: pointer;
        position: relative;
        transition: background-color 0.2s ease;
    }

    .item_row.data:active {
        background-color: rgba(255, 255, 255, 0.05);
    }
}
</style>
